import React from "react"
import dynamic from "next/dynamic"
import { useInView } from "react-cool-inview";

import { useGlobalContext } from "../../context/layout-context"

const StayInTheLoop = dynamic(() => import("../forms/stay-loop"))
const FooterLink = dynamic(() => import("../buttons/footer-link"))
const Title = dynamic(() => import("../title/title"))
const IconType = dynamic(() => import("../icons/icons-group"))

function Footer({ footerButtons, socialLinks, footerLinks }) {
  const { globalState } = useGlobalContext()
  const { isMobile, isTablet } = globalState || {}

  const { observe, inView } = useInView({
    threshold: 0.25, // Default is 0
    rootMargin: "100px 0px",
    onEnter: ({ unobserve }) => unobserve(),
  });

  return (
    <footer 
      className="bg-black py-9 lg:py-11 text-white relative"
      ref={observe}
      data-layer-region="footer"
    >
      {inView && (
        <div className="mx-auto max-w-350px container lg:max-w-1280px">
          <div className="flex items-stretch justify-start flex-wrap -ml-32 lg:-ml-48 -mt-8">
            <div
              className="w-full lg:w-1/3 pl-32 lg:pl-48 text-left mt-8 lg:flex lg:flex-wrap lg:items-end mb-2"
              data-layer-component="footer in view"
            >
              <div className="w-full mb-10">
                <Title
                  content="Stay in the loop"
                  colorTitle="text-yellow-700"
                  tag="h5"
                  classList="mb-0 text-sm"
                />
                <StayInTheLoop classList="mt-4 mb-8" />
                <p className="text-xs mb-0">
                  Sign me up to Nando's emails for access to exclusive events and
                  Extra Hot contests!
                </p>
              </div>
              {footerButtons.items.length > 0 && (
                <div className="w-full">
                  <Title
                    content="Get the app"
                    colorTitle="text-yellow-700"
                    tag="h5"
                    classList="mb-0 text-sm"
                  />
                  <div className="flex items-stretch -ml-2 -mt-4">
                    {footerButtons.items.map(button => (
                      <React.Fragment key={button.iconType}>
                        <div className="pl-0">
                          <IconType
                            type={button.iconType}
                            classList="w-full max-w-150px h-16"
                            url={button.slugUrl}
                            trackingEvent="FooterNavigationClick"
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {isMobile || isTablet ? (
              <div className="w-full lg:w-1/3 pl-32 lg:pl-48 text-center lg:text-right mt-8">
                <nav>
                  <ul className="block -mt-2">
                    {footerLinks.items.map(item => (
                      <li
                        key={item.name}
                        className="mb-0"
                        data-layer-menu={item.name}
                      >
                        <FooterLink
                          href={`${item.slugUrl}/`}
                          classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                        >
                          {item.name}
                        </FooterLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            ) : (
              <div className="w-full lg:w-1/3 pl-32 lg:pl-48 text-center lg:text-center mt-8 lg:flex lg:flex-wrap lg:items-end -ml-4 mb-5">
                <div className="flex items-stretch justify-center flex-wrap w-full">
                  {socialLinks.items.map(item => (
                    <div className="pl-4" key={item.iconType}>
                      <IconType
                        type={item.iconType}
                        classList="text-yellow-700 w-5 h-5"
                        url={`${item.slugUrl}/`}
                      />
                    </div>
                  ))}
                  <div className="pl-4 w-full mt-4">
                    <p className="text-sm text-white mb-0">© Nando’s 2024</p>
                  </div>
                </div>
              </div>
            )}
            {isMobile || isTablet ? (
              <div className="w-full lg:w-1/3 pl-32 lg:pl-48 text-center lg:text-center mt-8">
                <div className="flex items-center justify-center flex-wrap -ml-4">
                  {socialLinks.items.map(item => (
                    <div className="pl-4" key={item.iconType}>
                      <IconType
                        type={item.iconType}
                        classList="text-yellow-700 w-5 h-5"
                        url={`${item.slugUrl}/`}
                      />
                    </div>
                  ))}
                  <div className="pl-4 w-full mt-4">
                    <p className="text-sm text-white mb-0">© Nando’s 2024</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full lg:w-1/3 pl-32 lg:pl-48 text-center lg:text-right mt-8 lg:flex lg:flex-wrap lg:items-center mb-3">
                <nav className="w-full">
                  <ul className="block -mt-2">
                    {footerLinks.items.map(item => (
                      <li
                        key={item.name}
                        className="mb-0"
                        data-layer-menu={item.name}
                      >
                        <FooterLink
                          href={`${item.slugUrl}/`}
                          classList="transition-all duration-300 text-xs px-4 pt-4 pb-3 lg:pt-8 lg:pb-7 first:pl-0 last:pr-0 uppercase hover:text-pink-500 h-full flex items-center"
                        >
                          {item.name}
                        </FooterLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      )}
    </footer>
  )
}

export default Footer
